export default [
        
    {
        title: 'Сводка данных',
        route: 'user-dashboard',
        icon: 'HomeIcon',
    },
    {
        title: 'Статистика',
        route: 'user-statistic',
        icon: 'ActivityIcon',
    },
    {
        title: 'Платежи',
        route: 'user-payments',
        icon: 'CreditCardIcon',
        tag: 'new'
    },
    {
        title: 'Выплаты',
        route: 'user-transactions',
        icon: 'ShuffleIcon',
    },
    {
        title: 'Аккаунт',
        route: 'user-account',
        icon: 'UserIcon',
    }   
]